<template>
<div>
                    <div id="snackbar"></div>
                    <h1>Comming Home</h1>

</div>
    
</template>

<script>
import ReportService from "@/services/CinemaAndDistributorService/CinemaAndDistributorReportService";

export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  beforeMount() {
    this.getSeatLayoutData();
  },
  mounted: function() {},
  methods: {
    getSeatLayoutData: function() {
      let payload = {
        CinemaScheduleID: 2,
        CategoryID: 4,
        ShowType: ""
      };
      ReportService.getSeatLayoutData(payload)
        .then(response => {
          console.log("Get Seat layout", response);
          if (response.data.Status && response.data.Data.length > 0) {
            this.items = response.data.Data;
          } else {
            this.showMessageSnackbar(response.data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(error);
        });
    },
    // Show Message
    showMessageSnackbar: function(Message) {
      let x = document.getElementById("snackbar");
      // Add the "show" class to DIV
      x.className = "show";
      x.innerHTML = Message;
      // After 3 seconds, remove the show class from DIV
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  updated() {}
};
</script>

<style>
</style>
